/* eslint-disable react-hooks/exhaustive-deps */
import { useState, Fragment, useEffect } from "react";
import { Box, Grid, Slider, Tab } from "@mui/material";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import OutlinedInput from "@mui/material/OutlinedInput";
import PercentIcon from "@mui/icons-material/Percent";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import TrackChangesIcon from "@mui/icons-material/TrackChanges";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Chip from "@mui/material/Chip";
import { setCondsAndUncondsObj } from "../../../store/reducers/contracts";
import { useDispatch, useSelector } from "react-redux";
import FormHelperText from "@mui/material/FormHelperText";
import Authorization from "../../../utils/authorization";

import "./ConditionalUnconditional.scss";

const PrettoSlider = styled(Slider)({
  color: "#046481",
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&::before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 50,
    height: 32,
    borderRadius: "0px",
    backgroundColor: "#046481",
    "&::before": { display: "none" },
  },
});

function createData(name, value1, value2) {
  return { name, value1, value2 };
}

const ConditionalUnconditional = ({ isNextBtnDisabled }) => {
  // const [ratioDate, setRatioDate] = useState("April 25, '23");
  const [uncDiscount, setUncDiscount] = useState(0);
  const [cDiscount, setcDiscount] = useState(0);
  const [sliderValue, setSliderValue] = useState(0);
  const [value, setValue] = useState("1");
  const [salesValue, setSalesValue] = useState(0);
  const [targetValue, setTargetValue] = useState(0);
  const [pyTradeDiscount, SetPyTradeDiscount] = useState(0);
  const [errorDiscount, SetErrorDiscount] = useState({ name: "", text: "" });
  const [isBlur, SetIsBlur] = useState(false);
  const [accID, setAccId] = useState("");
  const isAmericanCountry = Authorization.isAmerica();
  const [currency, setCurrency] = useState(isAmericanCountry ? "" : "(€)");
  const [isEitherCondsAndUnConds, setIsEitherCondsAndUnConds] = useState(false);

  const dispatch = useDispatch();
  const { accounts, condsAndUncondsObj, prevSelectedAccounts } = useSelector((state) => state.contracts);
  const arrayCompare = (oldData, newData) => {
    if (oldData?.length !== newData?.length) return true;
    let result = false;
    for (let i = 0; i < oldData?.length; i++) {
      for (let j = 0; j < newData?.length; j++) {
        if (oldData[i]?.accountid === newData[j]?.accountid) {
          result = false;
        } else {
          result = true;
        }
      }
    }
    return result;
  };

  useEffect(() => {
    if (accounts?.length > 0) {
      //When new update accountid mapped to checkbox in Contracts Table
      // If account id new changes after set api response value in discount input text box
      let isAccountsUpdated = false;
      if (prevSelectedAccounts?.length > 0) {
        isAccountsUpdated = arrayCompare(prevSelectedAccounts, accounts);
      }
      let maxDiscountValue = accounts[0]?.pydiscountpercent;
      let salesSum = 0;
      let targetSum = 0;
      let obj = accounts[0];
      accounts.forEach((acc, accIndex) => {
        //total sum of selected PY Sales value
        if (acc?.pysales) {
          salesSum += acc?.pysales;
        }
        //total sum of selected Target value
        if (acc?.targetvalue) {
          targetSum += acc?.targetvalue;
        }
        if (accounts?.length === 1) {
          obj = { ...acc };
        } else if (accIndex > 0 && accounts[accIndex]?.pydiscountpercent > maxDiscountValue) {
          obj = { ...acc };
        }
      });
      const isSelectedAccID = !isAccountsUpdated ? (obj?.accountid === condsAndUncondsObj?.accoundID ? true : false) : false;
      //If accountid is existing after set old value of discount values, If not set response data value
      const isRetainSelValue = isSelectedAccID ? (Object.keys(condsAndUncondsObj)?.length > 0 ? true : false) : false;
      const pyuncondsdiscountper = isRetainSelValue ? condsAndUncondsObj?.uncDiscount : obj?.pyunconditionaldiscountpercent;
      const pycondsdiscountper = isRetainSelValue ? condsAndUncondsObj?.cDiscount : obj?.pyconditionaldiscountpercent;
      const pydiscountpercent = obj?.pydiscountpercent;

      setSalesValue(salesSum);
      setTargetValue(targetSum);
      setUncDiscount(pyuncondsdiscountper || 0);
      setcDiscount(pycondsdiscountper || 0);
      SetPyTradeDiscount(pydiscountpercent || 0);
      setAccId(obj?.accountid);
      const totalDisValue = Number(pycondsdiscountper) + Number(pyuncondsdiscountper);
      let unDiscountVal = totalDisValue ? (pyuncondsdiscountper * 100) / totalDisValue : 0;
      setSliderValue(unDiscountVal);
      const conditionalValue = pycondsdiscountper ? ((pycondsdiscountper * salesSum) / 100).toFixed(2) : 0;
      if (!isSelectedAccID)
        dispatch(
          setCondsAndUncondsObj({
            condsAndUncondsObj: {
              conditionalValue,
              cDiscount: pycondsdiscountper || 0,
              uncDiscount: pyuncondsdiscountper || 0,
              accoundID: obj?.accountid,
              totalPYsales: salesSum,
              targetSum: targetSum,
              error: {},
              totalPYSales: salesSum,
              contractCountryCode: obj?.countrycode || "",
            },
          })
        );
    }
  }, []);

  useEffect(() => {
    if (condsAndUncondsObj?.error?.name) {
      isNextBtnDisabled(true);
      SetErrorDiscount(condsAndUncondsObj?.error);
    } else {
      SetErrorDiscount({});
      isNextBtnDisabled(false);
    }
  }, [condsAndUncondsObj]);

  useEffect(() => {
    if (isBlur && (cDiscount || uncDiscount)) {
      const conditionalValue = ((cDiscount * salesNumber) / 100).toFixed(2);
      dispatch(
        setCondsAndUncondsObj({
          condsAndUncondsObj: {
            conditionalValue,
            uncValue,
            cDiscount,
            uncDiscount,
            accoundID: accID,
            totalPYsales: salesValue,
            targetSum: targetValue,
            error: errorDiscount,
            totalPYSales: salesValue,
          },
        })
      );
      SetIsBlur(false);
    }

    if (!Number(cDiscount) && !Number(uncDiscount)) {
      setIsEitherCondsAndUnConds(true);
    } else if ((!Number(cDiscount) && Number(uncDiscount)) || (Number(cDiscount) && !Number(uncDiscount))) {
      setIsEitherCondsAndUnConds(false);
    } else {
      setIsEitherCondsAndUnConds(false);
    }
  }, [cDiscount, uncDiscount, isBlur, salesValue]);

  const salesNumber = value === "1" ? salesValue : targetValue;

  const handleInputUncond = (e) => {
    //disable alphabet letters
    let uncDis = e.target.value.replace(/^[A-Za-z]+$/g, "");
    setUncDiscount(uncDis);
    let totalDisValue = Number(uncDis) + Number(cDiscount);
    //Total discount value (Unconds and conds discount) should be less than equal 100
    if (totalDisValue <= 100) {
      let sval = (uncDis * 100) / totalDisValue;
      setSliderValue(sval);
    }
    //validate value should not accept more than 100
    handleErrorDiscount(uncDis, cDiscount, e.target.name);
  };

  const handleInputCond = (e) => {
    //disable alphabet letters
    let cDis = e.target.value.replace(/^[A-Za-z]+$/g, "");
    setcDiscount(cDis);
    //Total discount value (Unconds and conds discount) should be less than equal 100
    let totalDisValue = Number(uncDiscount) + Number(cDis);
    let sval = (cDis / totalDisValue) * 100;
    let uncRatio = 100 - sval;
    if (totalDisValue <= 100) {
      setSliderValue(uncRatio);
    }
    //validate value should not accept more than 100
    handleErrorDiscount(cDis, uncDiscount, e.target.name);
  };
  /**
   * This function is used to  discount values(Unconds and conds discount) should not be greater than 100
   * @param {*} cDis
   * @param {*} uncDis
   * @param {*} name
   */
  const handleErrorDiscount = (cDis, uncDis, name) => {
    let addDisValue = Number(cDis) + Number(uncDis);
    if (addDisValue > 100) {
      isNextBtnDisabled(true);
      SetErrorDiscount({
        name: name,
        text: "The discount values are should not be greater than 100",
      });
    } else {
      SetErrorDiscount({});
      isNextBtnDisabled(false);
    }
  };
  const uncValue = ((uncDiscount * salesNumber) / 100).toFixed(2);
  const cValue = ((cDiscount * salesNumber) / 100).toFixed(2);
  const handleBlur = (e) => {
    SetIsBlur(true);
    if (e.target.value === "") {
      if (e.target.name === "cDiscount") {
        setcDiscount(0);
      } else {
        setUncDiscount(0);
      }
    }
  };

  useEffect(() => {
    const handleTabClose = (event) => {
      event.preventDefault();
      return (event.returnValue = "Are you want to exit?");
    };
    window.addEventListener("beforeunload", handleTabClose);
    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);
  const condSliderValue = sliderValue ? Math.round(sliderValue) : 0;
  const rows = [
    createData("Ratio", Math.round(condSliderValue), Math.round(100 - condSliderValue)),
    createData(
      "Discount",
      <Fragment>
        <OutlinedInput
          type="text"
          sx={{ width: "100px" }}
          value={uncDiscount}
          onChange={(e) => {
            handleInputUncond(e);
          }}
          onBlur={handleBlur}
          inputProps={{ maxLength: 5 }}
          name="uncDiscount"
          error={errorDiscount?.name === "uncDiscount" ? true : false}
        />
        {errorDiscount?.name === "uncDiscount" && (
          <FormHelperText id="component-helper-text" error>
            {errorDiscount?.text}
          </FormHelperText>
        )}
      </Fragment>,
      <Fragment>
        <OutlinedInput
          type="text"
          value={cDiscount}
          sx={{ width: "100px" }}
          onChange={(e) => handleInputCond(e)}
          inputProps={{ maxLength: 5 }}
          name="cDiscount"
          onBlur={handleBlur}
          error={errorDiscount?.name === "cDiscount" ? true : false}
        />
        {errorDiscount?.name === "cDiscount" && (
          <FormHelperText id="component-helper-text" error>
            {errorDiscount?.text}
          </FormHelperText>
        )}
      </Fragment>
    ),
    createData("Value", uncValue, cValue),
  ];

  const handleSliderChange = (event) => {
    let val = event.target.value;
    setSliderValue(val);
    let condRatio = 100 - val;
    let totalDisValue = Number(uncDiscount) + Number(cDiscount);
    let uncDis = (val / 100) * totalDisValue;
    let adjuncdis = uncDis.toFixed(2);
    let cDis = ((condRatio / 100) * totalDisValue).toFixed(2);
    setUncDiscount(adjuncdis);
    setcDiscount(cDis);
  };
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderTabLabel = (condImg, label, percentage, condTabValue) => {
    return (
      <Box display="flex" alignItems={"center"} justifyContent={"center"}>
        {condImg}
        <Typography sx={{ marginRight: "1vw" }} component="div" variant="body1">
          {label}
        </Typography>
        <Chip
          label={percentage}
          sx={{
            backgroundColor: condTabValue === value ? "aliceblue" : "",
            color: "inherit",
          }}
        />
      </Box>
    );
  };
  const handleCompletedSlider = () => {
    SetIsBlur(true);
  };
  const tabsIconSx = { height: "20px", width: "20px", marginRight: "3px" };
  const renderTableAndSlider = () => {
    const condSliderValue = sliderValue ? Math.round(sliderValue) : 0;
    return (
      <Fragment>
        <Grid item xs={12}>
          <Box style={{ display: "flex", alignItems: "center" }}>
            <PrettoSlider
              valueLabelDisplay="auto"
              aria-label="pretto slider"
              defaultValue={20}
              value={condSliderValue}
              onChange={handleSliderChange}
              disabled={errorDiscount?.name || isEitherCondsAndUnConds ? true : false}
              onChangeCommitted={handleCompletedSlider}
            />
            <p style={{ marginLeft: "20px" }}>{condSliderValue}%</p>
          </Box>
        </Grid>

        {/* table */}
        <Grid item xs={12}>
          <TableContainer component={Paper} style={{ borderRadius: "12px" }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow className="table-row" style={{ backgroundColor: "#00617f" }}>
                  <TableCell sx={{ width: "25%" }}> </TableCell>
                  <TableCell align="left" sx={{ color: "white", width: "37.5%" }}>
                    Unconditional
                  </TableCell>
                  <TableCell align="left" sx={{ color: "white", width: "37.5%" }}>
                    Conditional
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    style={{
                      borderBottom: "1.5px solid rgb(194 225 239 / 47%)",
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="left">{row.value1}</TableCell>
                    <TableCell align="left">{row.value2}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <span style={{ fontSize: "10px" }}>
            Last time ratio changed: {ratioDate}
          </span> */}
        </Grid>
      </Fragment>
    );
  };

  const tabIndicatorDisabled = () => {
    if (!isAmericanCountry) {
      return {
        "& .MuiTabs-indicator": {
          backgroundColor: "#FFF",
        },
        "& .Mui-selected": {
          color: "#000 !important",
        },
      };
    } else {
      return {
        "& .MuiTabs-indicator": {
          backgroundColor: "#0091DF",
        },
        "& .Mui-selected": {
          color: "#0091DF !important",
        },
      };
    }
  };

  return (
    <div>
      <Grid spacing={2} container>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <TabList onChange={handleTabChange} aria-label="lab API tabs example" sx={tabIndicatorDisabled}>
                <Tab
                  label={renderTabLabel(<AnalyticsIcon sx={tabsIconSx} />, `PY sales ${currency}`, salesValue, "1")}
                  value="1"
                  sx={{ textTransform: "none" }}
                />
                {isAmericanCountry && <Tab label={renderTabLabel(<TrackChangesIcon sx={tabsIconSx} />, "Targets", targetValue, "2")} value="2" />}
              </TabList>
              <Box>{renderTabLabel(<PercentIcon sx={tabsIconSx} />, "PY trade discount", `${pyTradeDiscount}%`)}</Box>
            </Box>
            <TabPanel value="1">{renderTableAndSlider()}</TabPanel>
            <TabPanel value="2">{renderTableAndSlider()}</TabPanel>
          </TabContext>
        </Box>
        {/* Slider */}
      </Grid>
    </div>
  );
};

export default ConditionalUnconditional;
