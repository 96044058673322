// material-ui
import { Box, IconButton, Typography, useMediaQuery } from "@mui/material";
// import AutorenewIcon from "@mui/icons-material/Autorenew";
import { ADMIN_ROLE } from "../../../constants";

// project import
// import Search from "./Search";
import Profile from "./Profile";
// import Notification from "./Notification";
import MobileSection from "./MobileSection";
import CountrySelect from "./CountrySelect";
import { useLocation } from "react-router-dom";
import YearSelect from "./YearSelect";
import { Fragment } from "react";
import Popper from "../../../components/ui/Popper";
import { useEffect, useState, useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { CustomButton } from "../../../components/ui/Button";
import TrialExecFilter from "./TrialExecFilter";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useDispatch } from "react-redux";
import { apiURL } from "../../../services/apiUrlConfig";
import { getRequest } from "../../../services/apiUtils";
import { openSnackbar } from "../../../components/ui/Toastr";
import { ErrorMsg } from "../../../utils/helper";
import Authorization from "../../../utils/authorization";
import Loader from "../../../components/ui/Loader";
import util from 'util'

import { activeCountryCode, selectedYearValue, selectedTrialExecValue,getCountryList } from "../../../store/reducers/common";
// ==============================|| HEADER - CONTENT ||============================== //

const initialFilters= {
  countryCode: "ALL",
  selectedYear: new Date().getFullYear(),
  selectedTrialExec: "ALL",
}
const HeaderContent = () => {
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const { pathname } = useLocation();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [selectedFilters, setSelectedFilters] = useState(initialFilters);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [isLoader, SetLoader] = useState(false);
  const authUser = Authorization.getAuthUser();
  const userHasRequiredRole =
  authUser && [ADMIN_ROLE]?.includes(authUser.userrole) ? true : false;
  
  useEffect(() => {
    getCountryLoadData();
  }, []);

  const getCountryLoadData = () => {
    const { analytics } = apiURL;
    const getApi = analytics.getCountry ?? ''
    const userDetaisl = Authorization.authUser
    const getCountryIntrfce = util.format(getApi, userDetaisl.id)
    SetLoader(true);
    getRequest({
      url: getCountryIntrfce,
    })
      .then((response) => {
        SetLoader(false);
        const result = response?.body?.data;
        if (result?.length > 0) {
          let options = [{ value: "ALL", label: "ALL" }];
          const selectOptions = [];
          result.forEach((rst) => {
            if (rst?.countrycode && rst?.countryname) {
              let obj = { value: rst?.countrycode, label: rst?.countryname };
              selectOptions.push({ ...obj });
            }
          });
          const updatedOptions= [...options,...selectOptions]
          setDropdownOptions(updatedOptions);
          dispatch(getCountryList({ countryList: result}));
        }
      })
      .catch((error) => {
        SetLoader(false);
        const errorRes = ErrorMsg(error);
        openSnackbar({ message: errorRes, variant: "error" });
      });
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  const handleApply = (event) => {
    dispatch(activeCountryCode({ countryCode: selectedFilters?.countryCode }));
    dispatch(selectedYearValue({ selectedYear: selectedFilters?.selectedYear }));
    dispatch(selectedTrialExecValue({ selectedTrialExec: selectedFilters?.selectedTrialExec }));
    handleClose(event);
  };

  const handleReset = () => {
    dispatch(activeCountryCode({ countryCode: initialFilters?.countryCode }));
    dispatch(selectedYearValue({ selectedYear: initialFilters?.selectedYear }));
    dispatch(selectedTrialExecValue({ selectedTrialExec: initialFilters?.selectedTrialExec }));
  };

  const handleGetSelectedValue = (value, condIf) => {
    setSelectedFilters({ ...selectedFilters, [condIf]: value });
  };
  return (
    <Box display="flex" alignItems="center" gap={1}>
       {isLoader && <Loader />}
      {matchesXs && <Box sx={{ width: "100%", ml: 1 }} />}
      {/* {!matchesXs && <Search />}
      <IconButton
        component={Link}
        // href=""
        // target="_blank"
        disableRipple
        color="secondary"
        title="refresh"
        sx={{ color: "text.primary", bgcolor: "grey.100" }}
      >
        <AutorenewIcon />
      </IconButton>

      <Notification /> */}
      {userHasRequiredRole && (
      <Fragment>
        <IconButton ref={anchorRef} aria-controls={open ? "profile-grow" : undefined} aria-haspopup="true" onClick={handleToggle}>
          <FilterAltIcon />
        </IconButton>
        <Popper anchorRef={anchorRef} open={open} handleToggle={handleToggle} handleClose={handleClose}>
          <Box padding={"16px 8px"}>
            <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} gap={1} margin={"0 8px"}>
              <Box>
                <Typography>Filters</Typography>
              </Box>
              <IconButton size="small" onClick={handleToggle}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box display={"flex"} flexDirection={"column"} gap={1} m={1}>
              <Box>
                <CountrySelect getValue={handleGetSelectedValue} options={dropdownOptions} />
              </Box>
              {pathname === "/target-dashboard" ? (
                <Fragment>
                  <Box>
                    <YearSelect getValue={handleGetSelectedValue} />
                  </Box>
                  <Box>
                    <TrialExecFilter getValue={handleGetSelectedValue} />
                  </Box>
                </Fragment>
              ) : null}
            </Box>
            <Box display="flex" justifyContent={"space-around"} alignItems="center" sx={{ width: "100%" }} mt={2}>
              <CustomButton
                label="Reset"
                handleClick={handleReset}
                sx={{
                  color: "#10384F",
                  borderRadius: "28px",
                  backgroundColor: "#FFFFFF",
                  border: "1.35px solid #10384F",
                  opacity: "1",
                  width: "100px",
                }}
                labelSX={{
                  fontWeight: 600,
                }}
              ></CustomButton>
              <CustomButton
                label="Apply"
                handleClick={handleApply}
                sx={{
                  color: "#2B6636",
                  borderRadius: "28px",
                  backgroundColor: "#dcedc8",
                  opacity: "1",
                  "&:hover": {
                    backgroundColor: "#dcedc8",
                    color: "#66B512",
                    border: "1.35px solid #66B512",
                  },
                  width: "100px",
                }}
                labelSX={{
                  fontWeight: 600,
                }}
              ></CustomButton>
            </Box>
          </Box>
        </Popper>
      </Fragment>
 )}
      {!matchesXs && <Profile />}
      {matchesXs && <MobileSection />}
    </Box>
  );
};

export default HeaderContent;
