
import React, { useState} from "react";
import "./doccreate.scss";

export const DocumentTextArea = (props) => {
    const [enterText] = useState(props.value);
  
    const EnterText = (event) => {
      if (props.onChange) {
        props.onChange(event.target.innerHTML);
      }
    };

    return (
      <div
        contentEditable
        onInput={EnterText}
        className="custom-textarea"
        class="isResizable mostly-customized-scrollbar"
        style={{width:'100%',padding:10,outline:'none',minHeight:props?.hyt,borderRadius:5,borderColor:props?.clr??'lightgrey'}}
        dangerouslySetInnerHTML={{ __html: enterText }}
      />
    );
  };
  
