/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useState, useEffect } from "react";
import ContractsTable from "./ContractsTable";
import { CustomButton } from "../../../components/ui/Button";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import ConditionalUnconditional from "./ConditionalUnconditional";
import Activities from "./Activities";
import { activeItem } from "../../../store/reducers/menu";
// import ReactSelect from "../../../components/third-party/ReactSelect";
import { openSnackbar } from "../../../components/ui/Toastr";
import ActivitiesCriteria from "./Activities/Criteria";
import CreateContracts from "./CreateContracts";
import MainCard from "../../../components/MainCard";
import { Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { getPrevSelectedAccounts } from "../../../store/reducers/contracts";
import util from "util";
import Authorization from "../../../utils/authorization";
import { apiURL } from "../../../services/apiUrlConfig";
import { createContracts } from "../../../saga/saga/contractSaga"
import Loader from "../../../components/ui/Loader";
import {
  getAccountsSelected
} from "../../../store/reducers/contracts";

const submitActivitySchema = (
  selectedAccountIds,
  conditonalValue,
  unConditonalValue,
  activityTargets,
  discountpercent
) => {
  return {
    ids: selectedAccountIds,
    conditionaldiscountpercent: conditonalValue || 0,
    unconditionaldiscountpercent: unConditonalValue || 0,
    activitytargets: activityTargets || [],
    discountpercent:discountpercent || ''
  };
};
const submitActivitySubSchema = (data) => {
  return {
    activitytypeid: data.id || null,
    conditionaldiscountpercent: data.calories || 0,
    simulateddiscount: data.carbs || 0,
    isenabled: true,
    pyconditionaldiscountpercent: data.fat || 0,
    pydiscountvalue: data.protein || 0,
    weighteddiscountpercent : data.weighted || 0
  };
};

const Contracts = () => {
  const [step, setStep] = useState(0);
  const [accountsOptions, setAccountsOptions] = useState([]);
  const dispatch = useDispatch();
  const { drawerOpen } = useSelector((state) => state.menu);
  const { accounts, activitiesList } = useSelector((state) => state.contracts);
  const ContractActionType = useSelector((state) => state.common.ContractActionType);
  const action = useSelector((state) => state.common.ContractActionType)
  const isAmericanCountry = Authorization.isAmerica()

  const [isNextBtnDisabled, setIsNextBtnDisabled] = useState(false);
  const getAccounts = useSelector((state) => state.contracts.accounts);
  const getConditionalValues = useSelector(
    (state) => state.contracts.condsAndUncondsObj
  );
  const getSelectedActivities = useSelector(
    (state) => state.contracts.activitiesList
  );
  // eslint-disable-next-line 
  const [isLoader, SetLoader] = useState(false);

  const contractSubmissionSchema = async () => {
    const selectedAccounts =
      getAccounts &&
      getAccounts.map((account) => {
        return account.id;
      });
    var set = new Set(selectedAccounts);
    const selectedAccountIds = Array.from(set);
    const conditonalValue = getConditionalValues.cDiscount;
    const unConditonalValue = getConditionalValues.uncDiscount;
    const discountpercent = parseFloat(conditonalValue) + parseFloat(unConditonalValue);
    let activityTargets = [];
    activityTargets =
      (await getSelectedActivities) &&
      getSelectedActivities.map((item) => {
        return submitActivitySubSchema(item);
      });
    return submitActivitySchema(
      selectedAccountIds,
      conditonalValue,
      unConditonalValue,
      activityTargets,
      discountpercent
    );
  };
  const updateContracts = async () => {
    const createContractRequestBody = await contractSubmissionSchema();
    const getApi = apiURL.ppp.createContract ?? "";
    const userDetaisl = Authorization.authUser;
    const createContractApi = util.format(getApi, userDetaisl.id);
    dispatch(
      createContracts({
        url: createContractApi,
        body: createContractRequestBody,
      })
    );
  };
  useEffect(() => {
    if(ContractActionType&&ContractActionType?.type === 'EDIT'){
      setStep(3)
    }else{
      dispatch(getAccountsSelected({ accounts: []}));
      setStep(1)
    }
  
  }, [ContractActionType]);

  useEffect(() => {
    if (accounts?.length) {
      const result = accounts.map((as) => {
        let obj = { value: as.accountid, label: as?.accountname };
        return obj;
      });
      setAccountsOptions([...new Set(result)]);
    }
  }, [accounts]);

  const renderNext = (value) => {
    switch (step) {
      case 1:
        return <ContractsTable />;
      case 2:
        return (
          <ConditionalUnconditional
            isNextBtnDisabled={(value) => setIsNextBtnDisabled(value)}
          />
        );
      case 3:
        return <Activities renderNext={() => setStep(4)} />;
      case 4:
        return <ActivitiesCriteria renderNext={() => setStep(5)} />;
      case 5:
        return <CreateContracts />;
      default:
        break;
    }
  };
  useEffect(() => {
    if (step) {
      const list = [
        { id: "contracts", title: "Contracts/Targets", step: 1 },
        {
          id: "contracts",
          title: "Ratio conditional vs. unconditional",
          step: 2,
        },
        { id: "contracts", title: "Activities", step: 3 },
        { id: "contracts", title: "Activities Criteria", step: 4 },
        { id: "contracts", title: "Create Contracts", step: 5 },
      ];
      const findObj = list.find((li) => li.step === step);
      dispatch(activeItem({ openItem: [findObj] }));
    }
  }, [step]);

  const handleNext = async() => {
    const conditonalValue = getConditionalValues.cDiscount;
    if (accounts?.length > 0 && step < 3) {
      if (!isNextBtnDisabled) {
        setStep(step + 1);
      }
    } else if (step !== 3) {
      openSnackbar({
        message: "Please select alteast one account ",
        variant: "warning",
      });
    } 
    if (step === 3 && !activitiesList?.length) {
      setStep(3);
      openSnackbar({
        message: "Please select alteast one activity ",
        variant: "warning",
      });
    }else{
      if(step===2 && !Number(conditonalValue)){
        setStep(2);
        openSnackbar({
          message: "Conditional discount value should be greater than 0",
          variant: "warning",
        });
      }
      if(step === 3){
      const TOTALCALVALUE = getConditionalValues?.cDiscount
      const sumConsiderVal = isAmericanCountry?'calories':'calories'
      const calSum_sel = await activitiesList.reduce((a, b) => (a + parseFloat(b[sumConsiderVal])), 0).toFixed(2);
      const UnconditionalValue = parseFloat(TOTALCALVALUE).toFixed(2)
      // console.log("ksksksk",TOTALCALVALUE,activitiesList,calSum_sel,TOTALCALVALUE.toString() === calSum_sel.toString())
      if(Number(calSum_sel) === Number(UnconditionalValue)&&calSum_sel>0)
      {
        updateContracts()
      }else{
        openSnackbar({
          message: calSum_sel<=0?"Please enter valid values ":`Sum of activities conditional discount value ${calSum_sel} is less than mentioned value ( ${UnconditionalValue} )`,
          variant: "warning",
        });
      }
      }
    }
  };
  const handlePrevious = () => {
    if (step === 2) {
      dispatch(getPrevSelectedAccounts({ prevSelectedAccounts: accounts }));
    }
    setIsNextBtnDisabled(false);
    setStep(step - 1);
  };

  const SelectionAccounnts = () => {
    const uniqueAccountsOptions = accountsOptions && Array.from(new Set(accountsOptions.map(a => a.value)))
 .map(value => {
   return accountsOptions.find(a => a.value === value)
 })

    return (
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <Select
          value={""}
          onChange={() => {}}
          style={{
            width: 250,
            height: 40,
            fontSize: 12,
            backgroundColor: "white",
          }}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          MenuProps={{
            disableScrollLock: true,
          }}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return (
                <em style={{ fontStyle: "normal" }}>
                  {"List of accounts selected"}
                </em>
              );
            }
            return selected.join(", ");
          }}
        >
          <MenuItem sx={{ fontSize: 11 }} value="all">
            {"All accounts"}
          </MenuItem>
          {uniqueAccountsOptions &&
            uniqueAccountsOptions.map((itm, index) => {
              return (
                <MenuItem sx={{ fontSize: 11 }} value={itm.value}>
                  {itm.label}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    );
  };

  return (
    <Fragment>
      {isLoader && <Loader />}
      <Box display="flex" flexDirection="column" gap={1.5}>
        <Box sx={{ width: drawerOpen ? "95%" : "100%" }}>
          {step > 1 && accounts?.length > 0 && (
            <Box
              display={"flex"}
              alignItems="center"
              justifyContent="flex-end"
              mb={1}
              style={{ marginTop: "-15px" }}
            >
              <SelectionAccounnts />
              {/* <ReactSelect
              options={accountsOptions}
              handleChange={(selectedOptions, e) => {}}
              id="account-id"
              label="List of accounts selected"
              width={250}
            /> */}
            </Box>
          )}
          {step < 4 || step >= 5 ? (
            <MainCard elevation={0} border={false} content={true}>
              {renderNext()}
              <Box
                display="flex"
                justifyContent={`${step === 3 ? "space-between" : "flex-end"}`}
              >
                {step === 3 && (
                  <Box>
                    <Typography style={{ fontSize: 11,color:'transparent' }}>
                      *The simulated value is only an indication
                    </Typography>
                    <Typography style={{ fontSize: 11 ,color:'transparent'}} color="#D0021B">
                      Warning! Only 65% out of total of 10% has been assigned
                    </Typography>
                  </Box>
                )}
                <Box gap={2} style={{ marginTop: 10 }}>
                  {step > 1 && (
                    action.type !== 'EDIT'&&<CustomButton
                      label="Previous"
                      sx={{
                        color: "#0091DF",
                        borderRadius: "28px",
                        backgroundColor: "#FFFFFF",
                        opacity: "1",
                        border: "1px solid #0091DF",
                        width: "100px",
                        height: "35px",
                        fontSize: 11,
                      }}
                      labelSX={{
                        fontSize: 12,
                        fontWeight: "bold",
                        letterSpacing: 0.3,
                      }}
                      handleClick={handlePrevious}
                    ></CustomButton>
                  )}

                  <CustomButton
                    label="Next"
                    sx={{
                      color: "#fff",
                      borderRadius: "28px",
                      backgroundColor: "rgb(0, 145, 223)",
                      opacity: "1",
                      "&:hover": {
                        backgroundColor: "#0D47A1",
                        // border: "1.35px solid #66B512",
                      },
                      width: "100px",
                      marginLeft: "10px",
                      height: "35px",
                    }}
                    handleClick={handleNext}
                    className="cus-btn-arrow"
                    labelClassName={"label"}
                    labelSX={{
                      fontSize: 12,
                      fontWeight: "bold",
                      letterSpacing: 0.3,
                    }}
                  ></CustomButton>
                </Box>
              </Box>
            </MainCard>
          ) : (
            renderNext()
          )}
        </Box>
      </Box>
    </Fragment>
  );
};

export default Contracts;
