import {React,useState,useEffect,Fragment,} from 'react';
import { Modal, Typography, Box, Button, Paper } from '@mui/material';
import { FileDrop } from 'react-file-drop'
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import './doccreate.scss';
import pdfIcon from "../../../../assets/images/pdfIcon.png"
import LinearProgress from '@mui/material/LinearProgress';
import { apiURL } from "../../../../services/apiUrlConfig";
import util from "util";
import Authorization from "../../../../utils/authorization";
import CircularProgress from '@mui/material/CircularProgress';
import { openSnackbar } from "../../../../components/ui/Toastr";
import {useDispatch,useSelector } from "react-redux";
import Loader from "../../../../components/ui/Loader";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { getFilePath, resetStatus as getFilePathresetStatus } from "../../../../saga/saga/downloadFileSaga"


import {
  getDocumentData,
  // eslint-disable-next-line no-unused-vars
  resetStatus as resetDocument
} from "../../../../saga/saga/documentUpload"


import {
  getSignedDocumentData,
  // eslint-disable-next-line no-unused-vars
  resetStatus as resetSignedDocument
} from "../../../../saga/saga/uploadSignedDoc"

const ERROR_MSG = "Please check internet or try again!";
const SUCCESS = 200;

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

 const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 500,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  outline: 'none',
//   p: 4,
  display: 'flex',
  flexDirection: 'column'
};

const UploadDocument = (props) => {
  const [attached, setAttached] = useState();
  const [progress, setProgress] = useState(0);
  const [ViewProgress, setViewProgress] = useState(0);
  const [BayerSide, setBayerSide] = useState(false);
  const [DealerSide, setDealerSide] = useState(false);


  const { documentData } = useSelector((state) => state.common);
  const getDocumentDetails = useSelector((state) => state.documentUpload.uploadDocumentDetails);
  const getDocumentStatus = useSelector((state) => state.documentUpload.uploadDocumentLoading);
  const getDocumentError = useSelector((state) => state.documentUpload.uploadDocumentError);

  const getSignedDocumentDetails = useSelector((state) => state.uploadSignedDoc.uploadSignedDocumentDetails);
  const getSignedDocumentStatus = useSelector((state) => state.uploadSignedDoc.uploadSignedDocumentLoading);
  const getSignedDocumentError = useSelector((state) => state.uploadSignedDoc.uploadSignedDocumentError);


  const getDownloadFilePath = useSelector((state) => state.downloadFileSaga.filePathDetails);
  const getDownloadFilePathStatus = useSelector((state) => state.downloadFileSaga.filePathloading);
  const getDownloadFilePathError = useSelector((state) => state.downloadFileSaga.filepathError);

  const [pdfUrl, setPdfUrl] = useState('');
  const dispatch = useDispatch()


  const handleClose = () => {
    props.cancel()

  }
  useEffect(() => {
    return () => {
      dispatch(getFilePathresetStatus());
   }
}, [])

  const handleOk = (src) => {
    getDocLoad(documentData?.itmObj?.contractfile || '')
  }

  const downloadScheduleC = (src) => {
    const standardScheduleBdoc = process.env.REACT_APP_SCHEULDE_C_PATH
    const updateApi = apiURL.ppp.scheduleBdoc ?? "";
    const userDetaisl = Authorization.authUser;
    const createDocApi = util.format(updateApi, userDetaisl.id,standardScheduleBdoc);
    standardScheduleBdoc?dispatch(getFilePath(createDocApi)): popUpSuccessr('Filepath missing!', "error");
  }


  useEffect(() => {
    if (getDownloadFilePathError === true && !getDownloadFilePath?.body) 
     {
      const errormsg = getDownloadFilePath?.response?.data?.message;
      const noNetworkMessage = ERROR_MSG;
      const msg = errormsg?.length > 0 ? errormsg : noNetworkMessage;
      dispatch(getFilePathresetStatus())
      popUpSuccessr(msg, "error");
  
    }else if (getDownloadFilePath?.status === SUCCESS) {
      // window.removeEventListener('beforeunload',handleTabClose)
      dispatch(getFilePathresetStatus())
      popUpSuccessr('file download has been started', "success");
      const path = getDownloadFilePath?.body?.s3url
        const a = document.createElement('a')
        a.href = path
        // a.setAttribute("download", 'sample.pdf');
        a.download = path
        // event.preventDefault();
        a.target = '_self'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        // window.addEventListener('beforeunload',handleTabClose)
  
    }
  }, [getDownloadFilePathStatus,getDownloadFilePath]);
  
  const popUpSuccessr = (msg, type) => {
    openSnackbar({
      message: msg,
      variant: type,
    });
  };
  
  useEffect(() => {
    if (getSignedDocumentError === true && !getSignedDocumentDetails?.body) {
      const errormsg = getSignedDocumentDetails?.message;
      const noNetworkMessage = ERROR_MSG;
      const msg = getSignedDocumentDetails?.message ? errormsg : noNetworkMessage;
      dispatch(resetSignedDocument())
      popUpSuccessr(msg, "error");
    setProgress(0)
        openSnackbar({
          message: errormsg,
          variant: 'error',
        });
    } else if (getSignedDocumentDetails?.status === SUCCESS) {
      dispatch(resetSignedDocument())
      setProgress(0)
      setAttached('')
      handleClose()
      props.refreshAction(true)
      openSnackbar({
        message: 'File has been uploaded successfully!',
        variant: 'success',
      });
    }
  }, [getSignedDocumentStatus, getSignedDocumentDetails]);

  useEffect(() => {
    if (getDocumentError === true && !getDocumentDetails?.body) {
      const errormsg = getDocumentDetails?.message;
      const noNetworkMessage = ERROR_MSG;
      const msg = getDocumentDetails?.message ? errormsg : noNetworkMessage;
      dispatch(resetDocument())
      popUpSuccessr(msg, "error");
    } else if (getDocumentDetails?.status === SUCCESS) {
      dispatch(resetDocument())
      createDocument(getDocumentDetails?.body?.data)
    }
  }, [getDocumentStatus, getDocumentDetails]);


  const createDocument = async (resp) => {
    const byteKeys = await Object.keys(resp).map(Number).sort((a, b) => a - b);
    const maxKey = Math.max(...byteKeys)
     const arr = new Uint8Array(maxKey + 1)
     byteKeys.forEach(key => {
         arr[key] = resp[key]
     })
     const blob = new Blob([arr], { type: 'application/pdf' })
     const pdfurl = await URL.createObjectURL(blob)
  //  setPdfUrl(pdfurl)
  if (pdfurl) {
    setViewProgress(false)
    const printWindow = window.open(pdfurl, '', 'height=500, width=800')
    printWindow.onload = () => {
      printWindow.print();
    }
    setPdfUrl('')
  }
  }

  const getDocLoad = (fileKey) => {
    const updateApi = apiURL.ppp.getDocument ?? "";
    const userDetaisl = Authorization.authUser;
    const createDocApi = util.format(updateApi, userDetaisl.id);
    setPdfUrl('')
    setViewProgress(true)
    dispatch(getDocumentData(createDocApi + fileKey || ''))
  }

  const dragToUploadFiles = ({ target: { files } }) => {
   const fileType = files[0]?.name.split('.')
   const fileExtension = fileType?.length > 0 ? fileType[1]:'NO'
    if(fileExtension === 'pdf'){
        const AttachedFile = files[0]
        setAttached(AttachedFile)
    }
  }

  const removeAttachment = () => {
    setAttached('')
  }

  /* api call for uploading file */
  const sendingData = (file, id) => {
    setProgress(1)
    const dataform = new FormData()
    dataform.append('file', attached)
    const updateApi = apiURL.ppp.uploadSignedDocument ?? "";
    const userDetaisl = Authorization.authUser;
    const contractId = documentData?.itmObj?.id
    const uploadDocApi = util.format(updateApi, userDetaisl.id,contractId);
    dispatch(getSignedDocumentData({ url: uploadDocApi, body: dataform }))

    // axios.post(url, dataform, {
    //   headers: headrs
    // }).then(res => {
    //   setProgress(0)
    //   setAttached('')
    //   props.refreshAction(true)
    //   openSnackbar({
    //     message: 'File has been uploaded successfully!',
    //     variant: 'success',
    //   });
    // }).catch(error => {
    //   console.log('respafter upoad',error)
    //   setProgress(0)
    //   openSnackbar({
    //     message: error?.data?.message,
    //     variant: 'error',
    //   });
    // })
  }
  const BayerSideAccept = () => {
    setBayerSide(!BayerSide)
  }
  const DealerSideAccept = () => {
    setDealerSide(!DealerSide)
  }
  const styles = { width: 600, color: 'black',height:'100%',alignItems:'center',display:'flex',justifyContent:'center' };
  return (
    <Fragment>
    {ViewProgress && <Loader />}
    <Modal
      open={props.open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} style={{backgroundColor:'transparent'}}>
          <Button onClick={handleClose} style={{display:'flex',justifyContent:'flex-end',paddingRight:0,width:'100%',fontSize:12}}><span style={{color:'black',backgroundColor:'white',width:20,height:20,borderRadius:10,display:'flex',alignItems:'center',justifyContent:'center'}}>X</span></Button>
          <Paper elevation={0} style={{backgroundColor:'#00617F'}} style={{height:40,borderBottomLeftRadius:0,borderBottomRightRadius:0,display:'flex',flexDirection:'row',paddingLeft:10,paddingTop:10,paddingRight:10,backgroundColor:'#00617F'}}>
            <Paper elevation={0} sx={{minHeight:20,width:'30%',backgroundColor:'#00617F',color:'white',borderRadius:0}}>
                <Typography >Upload Document</Typography>
            </Paper>
            <Paper elevation={0} sx={{minHeight:20,width:'70%'}} style={{display:'flex',justifyContent:'end',backgroundColor:'#00617F'}}>
            {!attached&&<Button variant="contained" sx={{ mr: 0, width: 140,height:25,fontSize:11,backgroundColor:'#00617F',color:'#00617F',backgroundColor:'white' }} onClick={()=>handleOk(documentData)}>{'Print document'}</Button>}
            {<Button variant="contained" sx={{ ml:1,mr: 0, width: 240,height:25,fontSize:11,backgroundColor:'#00617F',color:'#00617F',backgroundColor:'white' }} onClick={()=>downloadScheduleC(documentData)}>{'Download ScheduleC Document'}</Button>}
            </Paper>
          </Paper>
          <Paper elevation={0} style={{backgroundColor:'white',borderRadius:0}}>
            <Box style={{display:'flex',alignItems:'center',justifyContent:'center',height:200,paddingLeft:20,paddingRight:20}}>
            <div style={styles}>
            <FileDrop style={{ height:'100%',width: '90%', backgroundColor: 'red', flexDirection: 'row', overflow: 'auto' }}
            onDrop={(files, event) => dragToUploadFiles({ target: { files } })}
            >
            {/* {(progress===0&&!attached)&&<Paper elevation={0} style={{paddingLeft:20,backgroundColor:'white',borderRadius:0,display:'flex',alignItem:'center',justifyContent:'center',paddingTop:2,paddingBottom:2}}>
            <FormGroup >
              <FormControlLabel style={{height:60}}  control={<Checkbox onChange={()=>BayerSideAccept()} checked={BayerSide} style={{width:12,height:12,paddingTop:0}} />} label={<Typography style={{fontSize:12,paddingBottom:9,paddingLeft:5}}> I have confirmed that the document has been signed by Bayer.</Typography>}/>
              <FormControlLabel style={{height:40,paddingBottom:25}}  onChange={()=>DealerSideAccept()} control={<Checkbox checked={DealerSide} style={{width:12,height:12,paddingTop:0 }} />} label={<Typography style={{fontSize:12,paddingBottom:9,paddingLeft:5}}>I have confirmed that the document has been signed by Dealer.</Typography>} />
            </FormGroup>
            </Paper>} */}
            {attached?<Box>
              {progress === 0&&<Button onClick={removeAttachment} style={{display:'flex',justifyContent:'flex-end',paddingRight:0,width:'100%',fontSize:9}}><span style={{color:'white',backgroundColor:'black',width:15,height:15,borderRadius:7}}>X</span></Button>}
              <Box>{progress > 0?<CircularProgress style={{marginBottom:15}}/>:<img src={pdfIcon} alt="" style={{ width: '100px',marginRight:8, height: 100, borderRadius: 8 }} />}</Box>
              <Typography>{progress > 0?'uploading please wait...':attached?.name}</Typography>
             </Box>:   
             <Button
             disabled={false}
             title={(BayerSide&&DealerSide)?'click to select and upload the signed document':'please verify'}
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
            style={{width:230,height:30,backgroundColor:'#00617F',fontSize:12,letterSpacing:0.5}}
            >
            Upload Signed Document
            <VisuallyHiddenInput
                type="file"
                accept='.pdf'
                onChange={dragToUploadFiles}
                multiple
            />
            </Button>}

            </FileDrop>
            </div>
            </Box>
            <Paper elevation={0} style={{backgroundColor:'white',borderRadius:0,display:'flex',alignItem:'center',justifyContent:'center',paddingTop:20,paddingBottom:20}}>
          {attached&&<Button variant="contained" sx={{ mr: 0, width: 140,height:30 }} color="success" onClick={sendingData}>{'Submit'}</Button>}
          </Paper>
          </Paper>
          {/* <Paper elevation={0} style={{backgroundColor:'white',borderRadius:0,display:'flex',alignItem:'center',justifyContent:'center',paddingTop:20,paddingBottom:20}}>
          {attached&&<Button variant="contained" sx={{ mr: 0, width: 140,height:30 }} color="success" onClick={sendingData}>{'Submit'}</Button>}
          </Paper> */}
        {/* <Paper elevation={0} style={{display:'flex',flexDirection:'row',height:'20%'}}>
          <Typography id="modal-modal-title"  component="h2" align="center" >
            {props.title}
          </Typography>
          <Button variant="outlined" sx={{ mr: 2, width: 130 }} onClick={handleClose} >{'Download'}</Button>
        </Paper>
        <Paper elevation={0} sx={{ pb: 4 }}>
          
        </Paper >
        <Paper align="center" elevation={0}>
          <Button variant="outlined" sx={{ mr: 2, width: 130 }} onClick={handleClose} >{'Cancel'}</Button>
          <Button variant="contained" sx={{ mr: 2, width: 130 }} color="success" onClick={handleOk}>{'Ok'}</Button>

        </Paper > */}
      </Box>
    </Modal>
    </Fragment>
  )
}

export default UploadDocument